import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from '@environments/environment';
import { LANGUAGES } from '@model/const/languages.const';
import { INavigationMenu } from '@modules/root/model/navigation-sidebar-menu.interface';
import { SIDEBAR_MENU } from '@modules/root/model/navigation-sidebar.const';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageService } from '@services/language/language.service';
import { UserService } from '@services/user/user.service';

@Component({
  selector: 'mt-navigation-sidebar-settings-tooltip',
  templateUrl: './navigation-sidebar-settings-tooltip.component.html',
  styleUrls: ['./navigation-sidebar-settings-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationSidebarSettingsTooltipComponent {
  /**
   * The language const
   */
  languages = LANGUAGES;

  /**
   * The sidebar submenus
   */
  sidebarMenus = SIDEBAR_MENU;

  /**
   * The environment variable
   */
  isCognismSignatures = environment.isCogSig;

  /**
   * Assigned tooltip values
   * @defaultValue { languageTooltipOpened: false, message: '', opened: {} as INavigationMenu }
   */
  @Input() tooltips = {
    languageTooltipOpened: false,
    message: '',
    opened: {} as INavigationMenu
  };

  /**
   * Triggers change when user hover the settings tooltip
   */
  @Output() toggleSettingsTooltip = new EventEmitter<boolean>();

  /**
   * Triggers change when user click the settings submenu toggle
   */
  @Output() toggleSettingsSubmenu = new EventEmitter<string>();

  /**
   * Triggers change when user click the language
   */
  @Output() toggleLanguageSubTooltip = new EventEmitter<string>();

  constructor(
    private userService: UserService,
    public languageService: LanguageService,
    public translateService: TranslocoService
  ) {}

  /**
   * Shows or hide the submenu by click over the respective menu
   * @remarks
   * The menus here are like `employees`, `campaigns`.
   * @param menu - The selecte menu
   */
  toggleSubmenuSidebar(menu: string): void {
    this.toggleSettingsSubmenu.emit(menu);
  }

  /**
   * Shows or hide the tooltip of language of settings menu
   */
  showHideLanguageSubtooltip(): void {
    this.toggleLanguageSubTooltip.emit();
  }

  /**
   * Logout from the application
   */
  logout(): void {
    this.userService.logout();
  }

  /**
   * Shows or hide the settings tooltip
   * @param status - The status of shows the settings tooltip
   */
  showHideSettingsTooltip(status: boolean) {
    this.toggleSettingsTooltip.emit(status);
  }
}
